import React, { useEffect, useRef, useState } from 'react';

import {
    DoodleBoardImg,
    FallenPaintBox,
    GridBox,
    GridBoxContainer,
    GridImg,
    PaintBox,
    PaintContainer,
    PaintImg,
    ResponsiveMaxWidth1024,
    WallBehindBackgroundBlack,
    WallBehindPicture,
    WallCanvasPicture,
    WallCanvasPictureBox,
    WallTopContents,
    WallTopFlexJustifySpaceBetween,
    WallTopLeft,
    WallTopRight,
} from './styles';
import {
    CommonImg,
    Width100ImgObjectFitContain,
} from '../../components/nav/styles';
import DoodleBoardTitleResponsiveImage from '../../assets/wall_behind_responsive.png';
import DoodleBoardTitleImg from '../../assets/doodle_board/Group 125.png';
import DoodleBoardWallImg from '../../assets/canvas_wall.png';
import DoodleBoardSendImg from '../../assets/doodle_board/click_box.png';
import DoodleBoardClickImg from '../../assets/doodle_board/send_box.png';
import FallenPaintImg from '../../assets/물감자국 1.png';
import sample from '../../assets/198.png';
import {
    FlexAlignCenterJustifyCenterColumnContainer,
    FlexAlignCenterJustifyCenterContainer,
    FlexRowContainer,
} from '../../components/container/styles';
import Canvas from '../../components/canvas';
import { RandomImgRender } from '../../utils';
import {
    DoodleBoardImage,
    example,
    PaintData,
} from '../../components/constant';
import CustomModal from '../../components/modal/CustomModal';
import SendModal from '../../components/modal/SendModal';
import api from '../../api/api';
import useCanvas from '../../hooks/useCanvas';
import VirtualMsg from '../../components/virtualMsg';

const DoodleBoardPage = () => {
    const CANVAS_REF = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [maxWidth, setMaxWidth] = useState(window.innerWidth);
    const [value, setValue] = useState({ instagramId: '', twitterId: '' });

    const [pictureData, setPictureData] = useState([]);

    const [isPostComplete, setIsPostComplete] = useState(false);

    const [colorState, setColorState] = useState(PaintData[0].color);
    const { wallImageRender, RandomFunction } = RandomImgRender();
    const { thicknessSize } = useCanvas();

    useEffect(() => {
        const handleResize = () => {
            setMaxWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            // cleanup
            window.removeEventListener('resize', handleResize);
        };
    }, [maxWidth]);

    useEffect(() => {
        if (CANVAS_REF.current) {
            const canvas = CANVAS_REF.current;
            const context = canvas.getContext('2d');
            context.strokeStyle = colorState;
        }
    }, [colorState]);
    const sendPost = async () => {
        let body = {
            discord_id: value.instagramId,
            wallet_address: value.twitterId,
            picture_url: CANVAS_REF.current.toDataURL().split(',')[1],
        };
        if (value.discordId === '' || value.walletId === '') {
            alert('빈칸을 다 넣어주세요');
            return;
        }
        // setIsPostComplete(true);
        // setIsModalOpen(false);
        console.log(CANVAS_REF.current.toDataURL());
        try {
            const res = await api.post('/home/graffiti', body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(res);

            alert('Your doodle has been successfully submitted!');
            setIsPostComplete(true);
            setIsModalOpen(false);
            setValue((prev) => {
                let newData = Object.assign({}, prev);
                newData.walletId = '';
                newData.discordId = '';
                return newData;
            });
        } catch (e) {
            alert('An error occurred. Please try again.');
            console.log(e);
        }
    };

    const getPictureData = async () => {
        try {
            const res = await api.get('/home/graffiti', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(res);
            setPictureData(res?.data?.graffitiList);
        } catch (e) {
            alert('server err');
            console.log(e);
        }
    };

    useEffect(() => {
        if (isPostComplete) {
            const canvas = CANVAS_REF.current;

            const context = canvas.getContext('2d');
            canvas.width = 321;
            canvas.height = 317;
            context.lineCap = 'round'; //line 종류
            context.lineJoin = 'round'; // line 꺽이는
            context.strokeStyle = colorState;
            context.lineWidth = thicknessSize;
            setColorState(context.strokeStyle);
            const image = new Image();
            image.src = wallImageRender;
            image.onload = () => {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
            };
        }
    }, [isPostComplete]);
    //
    useEffect(() => {
        getPictureData();
    }, [isPostComplete]);
    return (
        <>
            {/*<CanvasDraw />*/}
            <CustomModal
                onClickSubmit={sendPost}
                value={value}
                setValue={setValue}
                isModalOpen={isModalOpen}
                name={'send'}
                setIsModalOpen={setIsModalOpen}
            />
            <DoodleBoardImg responsesivemaxwidth={maxWidth}>
                <ResponsiveMaxWidth1024 responsesivemaxwidth={maxWidth}>
                    <FlexAlignCenterJustifyCenterContainer>
                        <div style={{ padding: '30px' }}>
                            <Width100ImgObjectFitContain
                                style={{ maxWidth: '524px' }}
                                src={DoodleBoardTitleImg}
                            />
                        </div>
                    </FlexAlignCenterJustifyCenterContainer>
                    <WallBehindBackgroundBlack>
                        <FlexAlignCenterJustifyCenterColumnContainer
                            style={{
                                position: 'relative',
                                height: '100%',
                            }}
                        >
                            <div style={{ width: '100%', height: '100%' }}>
                                <WallBehindPicture src={DoodleBoardWallImg} />
                            </div>

                            <WallCanvasPictureBox>
                                <Canvas
                                    isModalOpen={isModalOpen}
                                    setColorState={setColorState}
                                    colorState={colorState}
                                    CANVAS_REF={CANVAS_REF}
                                    RenderImg={wallImageRender}
                                />
                            </WallCanvasPictureBox>
                            <WallTopContents>
                                <WallTopFlexJustifySpaceBetween>
                                    <WallTopLeft
                                        onClick={() => {
                                            RandomFunction('event');
                                        }}
                                    >
                                        <Width100ImgObjectFitContain
                                            src={DoodleBoardClickImg}
                                        />
                                    </WallTopLeft>
                                    <WallTopRight
                                        onClick={() => {
                                            setIsPostComplete(false);
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        <Width100ImgObjectFitContain
                                            src={DoodleBoardSendImg}
                                        />
                                    </WallTopRight>
                                </WallTopFlexJustifySpaceBetween>
                            </WallTopContents>
                        </FlexAlignCenterJustifyCenterColumnContainer>

                        <FlexAlignCenterJustifyCenterContainer>
                            <PaintContainer>
                                <PaintBox>
                                    {PaintData.map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => {
                                                    setColorState(item.color);
                                                }}
                                            >
                                                <PaintImg src={item.src} />
                                            </div>
                                        );
                                    })}
                                </PaintBox>
                                <FallenPaintBox>
                                    <img
                                        src={FallenPaintImg}
                                        alt={'FallenPaintImg'}
                                    />
                                </FallenPaintBox>
                            </PaintContainer>
                        </FlexAlignCenterJustifyCenterContainer>
                        <VirtualMsg />

                        <GridBoxContainer>
                            {pictureData?.map((item, i) => {
                                return (
                                    <GridBox key={i}>
                                        <div>
                                            {/*{item}*/}
                                            <GridImg src={item} />
                                        </div>
                                    </GridBox>
                                );
                            })}
                        </GridBoxContainer>
                    </WallBehindBackgroundBlack>
                </ResponsiveMaxWidth1024>
            </DoodleBoardImg>
        </>
    );
};

export default DoodleBoardPage;
