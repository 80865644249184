import React, { useEffect, useState } from 'react';

const useWindow = () => {
    const [maxWidth, setMaxWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setMaxWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            // cleanup
            window.removeEventListener('resize', handleResize);
        };
    }, [maxWidth]);
    return { maxWidth };
};

export default useWindow;
