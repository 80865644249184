import React from 'react';
import firstImg from '../../assets/img/Lenny-and-shawty 1.png';
import fourImg from '../../assets/img/Rad Cat and Party Boy.png';
import thirdImg from '../../assets/img/Jimmy and Johnny.png';
import twoImg from '../../assets/img/Business card.png';
import {
    Container,
    GridImage,
    ImageContainer,
    PictureText,
    PictureTextContainer,
    RootBox,
} from './styles';

const pictrue = [
    {
        id: 1,
        src: firstImg,
        style: { width: '100%', maxWidth: '610px', height: '100%' },
    },
    {
        id: 2,
        src: twoImg,
        style: { width: '100%', maxWidth: '424px', height: '100%' },
    },
    {
        id: 3,
        src: thirdImg,
        style: { width: '100%', maxWidth: '425px', height: '100%' },
    },
    {
        id: 4,
        src: fourImg,
        style: { width: '100%', maxWidth: '610px', height: '100%' },
    },
];

const Picture = () => {
    return (
        <RootBox>
            <Container>
                <ImageContainer>
                    <GridImage
                        style={{ ...pictrue[0].style }}
                        src={pictrue[0].src}
                    />
                </ImageContainer>
                <ImageContainer>
                    <GridImage
                        style={{ ...pictrue[1].style }}
                        src={pictrue[1].src}
                    />
                </ImageContainer>
            </Container>
            <Container dex={1}>
                <ImageContainer>
                    <GridImage
                        style={{ ...pictrue[2].style }}
                        src={pictrue[2].src}
                    />
                </ImageContainer>
                <ImageContainer>
                    <GridImage
                        style={{ ...pictrue[3].style }}
                        src={pictrue[3].src}
                    />
                </ImageContainer>
            </Container>
            <PictureTextContainer>
                <PictureText>
                    {
                        "If you own 10 pieces, you'll receive 1 special edition airdrop.\n   If you own 20 pieces, you'll receive 1 special edition airdrop and art print."                    }
                </PictureText>
            </PictureTextContainer>
        </RootBox>
    );
};

export default Picture;
