import React from 'react';
import {
    ButtonFlex,
    ButtonFlexGap10px,
    IntroduceContainer,
    IntroduceFlexBox,
    IntroduceFontBox,
    IntroduceImageBox,
    IntroduceImg,
    IntroduceRootBox,
} from './styles';

// import BuyOnOpenSea from '../../assets/faq/Archive/open sea.png';
import Wapal from '../../assets/v2/와팔구매.png';
import TradePort from '../../assets/v2/6.3.png';

import IntroduceImage from '../../assets/faq/faq_picture.png';
import { FlexRowContainer } from '../container/styles';
import { IntroduceButton } from '../button';

const Introduce = ({ ref }) => {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <IntroduceRootBox ref={ref}>
            <IntroduceContainer>
                <IntroduceFlexBox>
                    <IntroduceImageBox>
                        <IntroduceImg src={IntroduceImage} style={{}} />
                    </IntroduceImageBox>
                    <IntroduceFontBox>
                        <h3>Sneaky Downtown</h3>
                        <p>
                            Gabriel's collection of artworks, brings his unique
                            characters to life, drawing inspiration from
                            skateboarding and street art.
                        </p>
                        <p>
                            Born from the imagination of an artist seeking
                            excitement and fun with animal friends, Sneaky
                            Downtown breaks free from the ordinary.
                        </p>
                        <p>
                            Under the motto of "YOLO," these characters
                            prioritize enjoying life to the fullest, defying
                            conventions with joyful actions and creative ideas.
                        </p>
                        <p>
                            Meet 10 diverse characters, each symbolizing their
                            hobbies and motto, as you glimpse their thrilling
                            adventures and amusing escapades through a variety
                            of symbols.
                        </p>
                        <p>
                            Step into Gabriel's Sneaky Downtown and immerse
                            yourself in a world where boredom is banished, and
                            imagination knows no bounds!
                        </p>
                    </IntroduceFontBox>
                </IntroduceFlexBox>
                <ButtonFlexGap10px>
                    {/* <IntroduceButton
                        onClick={() => {
                            alert('Coming soon');
                        }}
                        img={''}
                    /> */}
                    <IntroduceButton
                        onClick={() => {
                            openInNewTab(
                                'https://www.tradeport.xyz/aptos/collection/gabriel-hollingtons-friends?tab=mint'
                            );
                        }}
                        alt={'buy on tradeport'}
                        img={TradePort}
                    />
                    <IntroduceButton
                        onClick={() => {
                            openInNewTab('https://wapal.io/');
                        }}
                        alt={'buy on wapal'}
                        img={Wapal}
                    />
                </ButtonFlexGap10px>
            </IntroduceContainer>
        </IntroduceRootBox>
    );
};

export default Introduce;
