import styled from 'styled-components';

export const Width100Container = styled.div`
    width: 100%;
`;

export const FlexAlignCenterJustifyCenterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
export const FlexAlignCenterJustifyCenterColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Width100FlexContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const MaxWidth1024Height100vh = styled.div`
    max-width: 1024px;
    width: 100%;
    //min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
`;

export const EmoticonImg = styled.img`
    //width: 140px;
    //height: 130px;
`;
export const FlexRowContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ImageWidth100ObjectFitContain = styled.img`
    width: 100%;
    object-fit: contain;
`;

export const RootContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 128px);
`;

export const PositionAbsoluteDisplayFlex = styled.div`
    position: absolute;
    display: flex;
`;
export const PositionRelative = styled.div`
    position: absolute;
    display: flex;
`;

export const FlexRowJustifyContentSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
