import styled from 'styled-components';
import { FlexRowContainer } from '../container/styles';

export const RepresentativeRootContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    h4 {
        white-space: nowrap;

        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        @media ${({ theme }) => theme.device.tablet} {
            //width: 55%;
            //height: 65%;
            font-size: 23px;
        }
    }
    p {
        color: #fe8208;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media ${({ theme }) => theme.device.tablet} {
            //width: 55%;
            //height: 65%;
            font-size: 14px;
        }
        @media ${({ theme }) => theme.device.mobileL} {
            //width: 55%;
            //height: 65%;
            font-size: 10px;
        }
    }
`;

export const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const ImageBoxContainer = styled.div`
    @media ${({ theme }) => theme.device.tablet} {
        padding: 0px 5px;
    }
`;
export const ImageBoxMapper = styled.div`
    margin-bottom: 20px;
    width: 100%;
`;
export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const TwoImageMapper = styled(FlexRowContainer)`
    @media ${({ theme }) => theme.device.tablet} {
        gap: 20px;
    }
    gap: 38px;
`;
