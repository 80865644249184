import React from 'react';
import styled from 'styled-components';
import SendModal from './SendModal';
import useModal from '../../hooks/useModal';

const CustomModal = ({
    value,
    setValue,
    name,
    onClickSubmit,
    isModalOpen,
    setIsModalOpen,
}) => {
    const { makeFindModal } = useModal(onClickSubmit, name, value, setValue);
    return (
        isModalOpen && (
            <ModalBackdrop
                onClick={(e) => {
                    setIsModalOpen(false);
                }}
            >
                {makeFindModal()}
                {/*<SendModal />*/}
            </ModalBackdrop>
        )
    );
};

export default CustomModal;

const ModalBackdrop = styled.div`
    position: fixed;
    z-index: 12342341239847123980471239804570918237409812;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: grid;
    place-items: center;
`;
