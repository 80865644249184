export const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = [
        'android',
        'webos',
        'iphone',
        'ipad',
        'ipod',
        'blackberry',
        'windows phone',
        'IEMobile',
        'Opera Mini',
    ];

    for (const keyword of mobileKeywords) {
        if (userAgent.indexOf(keyword) !== -1) {
            return true;
        }
    }

    return false;
};
