import styled from 'styled-components';

export const RootContainer = styled.div`
    margin-top: 30px;
    max-width: 722px;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 100px;
    @media ${({ theme }) => theme.device.tablet} {
        padding: 10px 40px;
    }
    button {
        max-width: 150px;
        width: 100%;
        margin-top: 20px;
        padding: 4px 0px;
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 18px;
        }
        border-radius: 10px;
        background: #fff;
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 15px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export const RootBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RootImgBox = styled.div`
    max-width: 20px;
    width: 100%;
    height: 21px;
`;

export const RootRightImgBox = styled(RootImgBox)`
    margin-right: 10px;
    @media ${({ theme }) => theme.device.tablet} {
    }
`;
export const RootLeftImgBox = styled(RootImgBox)`
    margin-left: 10px;
    @media ${({ theme }) => theme.device.tablet} {
    }
`;
