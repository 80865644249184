import {
    EmoticonImg,
    FlexAlignCenterJustifyCenterContainer,
    ImageWidth100ObjectFitContain,
} from './styles';

export const ImageContainer = ({ style, src }) => {
    return <ImageWidth100ObjectFitContain src={src} style={{ ...style }} />;
};

export const FlexAlignCenterJustifyCenterContainerSpreadStyle = ({ style }) => {
    return <FlexAlignCenterJustifyCenterContainer style={{ ...style }} />;
};

export const WallEmoticonContainer = ({ style, src }) => {
    return <EmoticonImg src={src} style={{ ...style }} />;
};
