import React, { useCallback, useEffect, useRef, useState } from 'react';

const useCanvas = (CANVAS_REF, RenderImg, isMobile, colorState) => {
    const isDrawing = useRef(false);

    const [drawingHistory, setDrawingHistory] = useState([]);
    const [thicknessSize, setThicknessSize] = useState(5);
    const [index, setIndex] = useState(-1);
    const startDrawing = (event) => {
        isDrawing.current = true;
        const canvas = CANVAS_REF.current;
        const context = canvas.getContext('2d');
        context.beginPath();
        const { event_offsetX, event_offsetY } = getCanvasOffset(event);
        context.moveTo(event_offsetX, event_offsetY);

        event.preventDefault();
    };

    const getCanvasOffset = (event) => {
        let currentTargetRect = event?.currentTarget?.getBoundingClientRect();

        const offsetX = isMobile ? event.touches[0].clientX : event.pageX;
        const offsetY = isMobile ? event.touches[0].clientY : event.pageY;
        const scrolledOffsetX = offsetX - window.pageXOffset;
        const scrolledOffsetY = offsetY - window.pageYOffset;

        // 보정된 좌표를 이용해 캔버스의 좌표를 계산합니다.
        const event_offsetX =
            ((scrolledOffsetX - currentTargetRect?.left) /
                currentTargetRect?.width) *
            CANVAS_REF?.current?.width;
        const event_offsetY =
            ((scrolledOffsetY - currentTargetRect?.top) /
                currentTargetRect?.height) *
            CANVAS_REF?.current?.height;

        return { event_offsetX, event_offsetY };
    };

    const draw = useCallback(
        (event) => {
            if (isDrawing.current) {
                const canvas = CANVAS_REF.current;
                const context = canvas.getContext('2d');
                const { event_offsetX, event_offsetY } = getCanvasOffset(event);

                context.lineTo(event_offsetX, event_offsetY);

                context.stroke();
            }

            event.preventDefault();
        },
        [getCanvasOffset]
    );

    const handleChangeLineWidth = (event) => {
        // 두께 조절
        const parsedValue = Number.parseFloat(event.target.value);
        setThicknessSize(parsedValue);
        if (CANVAS_REF.current) {
            const canvas = CANVAS_REF.current;
            const context = canvas.getContext('2d');
            context.lineWidth = parsedValue;
        }
    };
    const handleUndo = (ref) => {
        if (index >= 0) {
            const canvas = ref.current;
            const context = canvas.getContext('2d');
            setIndex((prev) => prev - 1);

            if (index > 0) {
                const prevImageData = drawingHistory[index - 1];
                setDrawingHistory((prev) => {
                    let newData = [...prev];
                    newData.pop();
                    return newData;
                });
                context.putImageData(prevImageData, 0, 0);
            } else {
                setIndex(-1);
                setDrawingHistory([]);
                const image = new Image();
                image.src = RenderImg;
                image.onload = () => {
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);
                };
            }
        }
        console.log(index);
    };

    const stopDrawing = (event) => {
        if (isDrawing.current) {
            const canvas = CANVAS_REF.current;
            const context = canvas.getContext('2d');
            context.stroke();
            context.closePath();
            isDrawing.current = false;

            setDrawingHistory((prev) => {
                const newData = [...prev];
                newData.push(
                    context.getImageData(0, 0, canvas.width, canvas.height)
                );
                setIndex(newData.length - 1);
                return newData;
            });
            setIndex((prevIndex) => prevIndex + 1);
        }

        event.preventDefault();
    };

    return {
        stopDrawing,
        handleChangeLineWidth,
        drawingHistory,
        handleUndo,
        index,
        draw,
        getCanvasOffset,
        startDrawing,
        thicknessSize,
    };
};

export default useCanvas;
