import { useCallback, useEffect, useState } from 'react';
import { DoodleBoardImage } from '../components/constant/index';

export const RandomImgRender = () => {
    const [wallImageRender, setWallImageRender] = useState(
        DoodleBoardImage[0].src
    );
    const DoodleBoardImageLength = DoodleBoardImage.length;

    const RandomFunction = useCallback(
        (click) => {
            if (click === 'event') {
                const currentIndex = DoodleBoardImage.findIndex(
                    (e) => e.src === wallImageRender
                );
                let nextIndex = currentIndex + 1;

                if (nextIndex >= DoodleBoardImageLength) {
                    nextIndex = 0;
                }

                setWallImageRender(DoodleBoardImage[nextIndex].src);
            }
        },
        [wallImageRender]
    );

    return { wallImageRender, RandomFunction };
};
