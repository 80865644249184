import React from 'react';
import {
    ArtistBox,
    ArtistImgBox,
    ArtistProfileImg,
    ArtistRootContainer,
    ArtistSection,
    ArtistSection2,
    ArtistSectionContainer,
    DisplayFlexArtist,
    IconBox,
    IconImg,
    IFrameBox,
    IFrameStyle,
} from './styles';

import ArtistProfile from '../../assets/faq/profile_introduce.png';
import WorldIconImg from '../../assets/faq/Group 74 3.png';
import TwitterImg from '../../assets/faq/트위.png';
import InstarImg from '../../assets/faq/인스.png';
import { FlexRowContainer } from '../container/styles';
import { useNavigate } from 'react-router-dom';
const Artist = () => {
    let ImgRender = [
        { link: 'https://www.gabrielhollington.com/', src: WorldIconImg },
        {
            link: 'https://twitter.com/i/flow/login?redirect_after_login=%2Farkpia',
            src: TwitterImg,
        },
        { link: 'https://www.instagram.com/arkpia/', src: InstarImg },
    ];

    const navigate = useNavigate();
    return (
        <div style={{ width: '100%', background: 'black' }}>
            <IFrameBox>
                <IFrameStyle
                    src='https://www.youtube.com/embed/NiGphqmj1AE'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; index-in-index; web-share'
                    allowFullScreen
                />
            </IFrameBox>
            <ArtistRootContainer>
                <ArtistSectionContainer>
                    <ArtistImgBox>
                        <ArtistProfileImg src={ArtistProfile} />
                    </ArtistImgBox>
                    <ArtistSection>
                        <h3>Gabriel Hollington</h3>
                        <h4>Artist & Illustrator, UK</h4>
                        <p>
                            London-based artist renowned for his vibrant and
                            captivating illustrations, welcomes you to a world
                            of extraordinary artistry. With a playful edge and
                            bold use of color, Gabriel's artworks exude humor
                            and charm, captivating hearts worldwide.
                        </p>
                        <p>
                            His artistic journey began with a fascination for
                            skateboard graphics and street art, igniting a love
                            for illustration that continues to shine through his
                            creations. Influenced by skateboarding and punk
                            rock, his unique style sets him apart as a beloved
                            figure in the art industry.
                        </p>
                    </ArtistSection>
                </ArtistSectionContainer>
                <ArtistSection2>
                    <h3>Collaborations with Global Brands</h3>
                    <p style={{ marginBottom: '20px' }}>
                        His work has graced renowned brands like Vans Global and
                        Vans Asia, leaving a mark on worldwide holiday
                        campaigns. His illustrations have been featured in Vans
                        stores across the globe, on the main page of vans.com,
                        in an online 2D game, and on various apparel.
                    </p>

                    <span>Other Collaborations:</span>
                    <p>
                        Ikea, Nickelodeon, The New Yorker, The New York Times,
                        The Washington Post, Li-Ning, UBS Bank, So Young
                        Magazine, Food and Wine Magazine, Bretterbude Skate,
                        Three Amigos Skate Shop, PRISM SKATE, Truck Festival,
                        Hopfully Brewing, Boba Records, and more.
                    </p>
                    <h3>Exhibitions</h3>
                    <p>
                        Gabriel's illustrious career has been celebrated in
                        notable exhibitions, including "That's Hot" at 101
                        Gallery, Germany, "Art in Motion" at Godspeed Gallery,
                        London, and "Boundless Creativity" at Take Courage
                        Gallery, London and more.
                    </p>
                </ArtistSection2>
                <DisplayFlexArtist>
                    {ImgRender.map((item, i) => {
                        return (
                            <IconBox target={'_blank'} href={item.link} key={i}>
                                <IconImg src={item.src} alt={''} />
                            </IconBox>
                        );
                    })}
                </DisplayFlexArtist>
            </ArtistRootContainer>
        </div>
    );
};

export default Artist;
