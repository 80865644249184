import styled from 'styled-components';

export const SendButtonStyle = styled.button`
    border-radius: 3px;
    border: 1px solid #ececec;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 85px;
    height: 27px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

export const IntroduceCommonButton = styled.button``;
