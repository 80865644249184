import React from 'react';
import NextImg from '../../assets/img/Group 128.png';
import { DivPre } from './NextArrow';

const PrevArrow = ({ onClick }) => {
    return (
        <DivPre onClick={onClick}>
            <img src={NextImg} />
        </DivPre>
    );
};

export default PrevArrow;
