import styled from 'styled-components';

export const ImageRootBoxCharacter = styled.div`
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
`;

export const RootBoxImage = styled.img`
    width: 100%;
    object-fit: contain;
`;

export const NumberImg = styled.img`
    max-width: 35px;
    @media ${({ theme }) => theme.device.mobileM} {
        max-width: 25px;
    }
`;

export const CharacterPageFlex = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
`;
export const RenderImageRootBox = styled.div`
    @media ${({ theme }) => theme.device.tablet} {
        padding: 20px;
        margin-top: 35px;
    }
    margin-top: 65px;
    display: flex;
    flex-direction: column;
`;

export const NumberBox = styled.div`
    width: 100%;

    display: flex;

    gap: 10px;
    max-width: 1024px;
    margin: 0px auto;
    margin-bottom: 30px;
`;

export const SubTitle = styled.span`
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media ${({ theme }) => theme.device.tablet} {
        font-size: 12px;
    }
    @media ${({ theme }) => theme.device.mobileM} {
        font-size: 10px;
    }
`;

export const ContentFlexBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
`;

export const GridBox = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1040px;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @media ${({ theme }) => theme.device.medium} {
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
    }
`;

export const ImageBox = styled.div`
    max-width: 250px;
    width: 100%;
    max-height: 478px;
    height: 100%;
`;
