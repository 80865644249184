import React, { useMemo } from 'react';
import {
    ContentBox,
    Image,
    ImageBoxContainer,
    ImageBoxMapper,
    RepresentativeRootContainer,
    TwoImageMapper,
} from './styles';
import originFirstImg from '../../assets/representative/1-1.png';
import firstImg from '../../assets/representative/1.png';
import twoImg from '../../assets/representative/2.png';
import threeImg from '../../assets/representative/3.png';
import fourImg from '../../assets/representative/4.png';
import fiveImg from '../../assets/representative/5.png';
import sixImg from '../../assets/representative/6.png';
import sevenImg from '../../assets/representative/7.png';
import eightImg from '../../assets/representative/8.png';
import nineImg from '../../assets/representative/9.png';
import tenImg from '../../assets/representative/10.png';
import elevenImg from '../../assets/representative/11.png';
import { FlexRowContainer } from '../container/styles';

const RepresentativeArtWorks = () => {
    let ImgRender = useMemo(
        () => [
            {
                id: 1,
                src: originFirstImg,
                style: {
                    marginTop: '20px',
                    width: '100%',
                    maxWidth: '1070px',
                    height: '100%',
                },
            },

            {
                id: 2,
                src: firstImg,
                style: { width: '100%', maxWidth: '1070px', height: '100%' },
            },
            {
                id: 3,
                children: (
                    <TwoImageMapper>
                        <div>
                            <Image src={twoImg} />
                        </div>
                        <div>
                            <Image src={threeImg} />
                        </div>
                    </TwoImageMapper>
                ),
            },
            {
                id: 5,
                children: (
                    <TwoImageMapper>
                        <div>
                            <Image src={fiveImg} />
                        </div>
                        <div>
                            <Image src={sixImg} />
                        </div>
                    </TwoImageMapper>
                ),
            },
            {
                id: 6,
                children: (
                    <TwoImageMapper>
                        <div>
                            <Image src={sevenImg} />
                        </div>
                        <div>
                            <Image src={eightImg} />
                        </div>
                    </TwoImageMapper>
                ),
            },
            {
                id: 8,
                src: nineImg,
                style: { width: '100%', maxWidth: '1070px', height: '100%' },
            },
            {
                id: 9,
                src: tenImg,
                style: { width: '100%', maxWidth: '1070px', height: '100%' },
            },
            {
                id: 10,
                src: elevenImg,
                style: { width: '100%', maxWidth: '1070px', height: '100%' },
            },
        ],
        []
    );
    return (
        <div style={{ width: '100%', background: 'black' }}>
            <RepresentativeRootContainer>
                <ContentBox>
                    <h4>Representative Artworks</h4>
                    <p>
                        Personal Projects / Brand Collaborations / Commissioned
                    </p>
                </ContentBox>
                <ImageBoxContainer>
                    {ImgRender.map((item, i) => {
                        return (
                            <ImageBoxMapper key={i} style={{ ...item.style }}>
                                {item.children}
                                <Image src={item.src} />
                            </ImageBoxMapper>
                        );
                    })}
                </ImageBoxContainer>
            </RepresentativeRootContainer>
        </div>
    );
};

export default RepresentativeArtWorks;
