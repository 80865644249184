import { SendButtonStyle } from './style';

export const SendButton = ({ text, onClick }) => {
    return <SendButtonStyle onClick={onClick}>{text}</SendButtonStyle>;
};

export const IntroduceButton = ({ text, onClick, img = null }) => {
    return (
        <div style={{ cursor: 'pointer' }} onClick={onClick}>
            <img
                style={{ width: '217px', height: '39px', borderRadius: '5px' }}
                src={img}
                alt=''
            />
        </div>
    );
};
