import styled from 'styled-components';

export const RootBox = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0px;
    @media ${({ theme }) => theme.device.tablet} {
        padding: 0px 10px;
    }
`;
export const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    margin: ${(props) => props.dex === 1 && '1px 0px'};
`;

export const ImageContainer = styled.div``;

export const GridImage = styled.img`
    width: auto;
    height: 100%;
    object-fit: contain;
`;

export const PictureText = styled.span`
    color: #fe8208;
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: pre-wrap;
    @media ${({ theme }) => theme.device.tablet} {
        font-size: 22px;
    }

    @media ${({ theme }) => theme.device.mobileXL} {
        font-size: 16px;
    }
    @media ${({ theme }) => theme.device.mobileL} {
        font-size: 10px;
    }
`;

export const PictureTextContainer = styled.div`
    text-align: center;
    margin: 30px 0px 30px 0px;
    @media ${({ theme }) => theme.device.mobileXL} {
        margin: 10px 0px 0px 0px;
    }
`;
