import styled from 'styled-components';

export const TopContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 128px;
    background: #000000;
`;

export const TopInnerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 41px;
    @media ${({ theme }) => theme.device.mobileXL} {
        flex-direction: column;
        gap: 10px;
    }
`;

export const FooterRightContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LineBar = styled.div`
    height: 31px;
    width: 1px;
    display: inline-block;
    background: black;
    //margin: 0px 42px;
`;
