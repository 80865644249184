import styled from 'styled-components';

export const ImageCarousel = styled.img`
    //object-fit: fill;

    object-fit: contain;
    margin: 5px 0px;
    //margin: 10px;

    width: 100%;
    height: 100%;
`;

export const CarouselStyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    //min-width: 320px;
    width: 100%;
`;
export const CarouselImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
