import {
    FaQBox,
    FaQDetailFontSize15,
    FontSize15_FE8208_Montserrat,
    FontSize18Montserrat,
    FontSize24Montserrat,
    FontSize25Montserrat,
} from './styles';

export const CharacterLabelFontSize24 = ({ style, text }) => {
    return (
        <FontSize24Montserrat style={{ ...style }}>{text}</FontSize24Montserrat>
    );
};
export const CharacterLabelFontSize18 = ({ style, text }) => {
    return (
        <FontSize18Montserrat style={{ ...style }}>{text}</FontSize18Montserrat>
    );
};

export const CharacterLabelFontSize25 = ({ style, text }) => {
    return (
        <FontSize25Montserrat style={{ ...style }}>{text}</FontSize25Montserrat>
    );
};
export const FaqBoxDetailLabelFontSize15 = ({ style, text }) => {
    return <p style={{ ...style }}>{text}</p>;
};

export const FaQBoxComponent = ({ style, text, detail }) => {
    return (
        <div>
            <FaQBox>
                <span>{text}</span>
            </FaQBox>
            <FaQDetailFontSize15>{detail}</FaQDetailFontSize15>
        </div>
    );
};
