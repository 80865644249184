import React from 'react';
import SendModal from '../components/modal/SendModal';
import NewsPaperModal from '../components/modal/NewsPaperModal';

const useModal = (onClickSubmit, name, value, setValue) => {
    let modalComponent = [
        {
            id: 1,
            name: 'send',
            component: (
                <SendModal
                    onClickSubmit={onClickSubmit}
                    value={value}
                    setValue={setValue}
                />
            ),
        },
        {
            id: 2,
            name: 'newspaper',
            component: <NewsPaperModal />,
        },
    ];
    const makeFindModal = () => {
        let modal = modalComponent.find((item) => item.name === name);
        return modal.component;
    };
    return { makeFindModal };
};

export default useModal;
