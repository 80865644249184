export const deviceSizes = {
    miniMobileXL: '280',
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '450px',
    tablet: '1024px',

    tabletL: '1024px',
    medium: '600px',
    mobileXL: '768px',
};

export const isDevice = {
    miniMobileXL: window.matchMedia('(max-width: 280)').matches,

    mobileS: window.matchMedia('(max-width: 320px)').matches,
    mobileM: window.matchMedia('(max-width: 375px)').matches,
    mobileL: window.matchMedia('(max-width: 450px)').matches,
    tablet: window.matchMedia('(max-width: 1024px)').matches,
    medium: window.matchMedia('(max-width:600px)').matches,
    tabletL: window.matchMedia('(max-width: 1024px)').matches,
    mobileXL: window.matchMedia('(max-width: 768px)').matches,
};
const device = {
    miniMobileXL: `only screen and (max-width: ${deviceSizes.miniMobileXL})`,

    mobileS: `only screen and (max-width: ${deviceSizes.mobileS})`,
    mobileM: `only screen and (max-width: ${deviceSizes.mobileM})`,
    mobileL: `only screen and (max-width: ${deviceSizes.mobileL})`,
    tablet: `only screen and (max-width: ${deviceSizes.tablet})`,
    medium: `only screen and (max-width:${deviceSizes.medium})`,
    tabletL: `only screen and (max-width: ${deviceSizes.tabletL})`,
    mobileXL: `only screen and (max-width: ${deviceSizes.mobileXL})`,
};

const theme = {
    device,
};

export default theme;
