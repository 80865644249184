import React from 'react';
import {
    RootBox,
    RootContainer,
    RootLeftImgBox,
    RootRightImgBox,
} from './styles';
import first from '../../assets/doodle_board/“”.png';
import second from '../../assets/doodle_board/“” (1).png';
import { FlexAlignCenterJustifyCenterContainer } from '../container/styles';
const VirtualMsg = () => {
    return (
        <RootContainer>
            <FlexAlignCenterJustifyCenterContainer>
                <RootBox>
                    <RootRightImgBox>
                        <img src={first} />
                    </RootRightImgBox>
                    <p>
                        Sneak into Gabriel's Virtual Art Space at Your Graffiti!
                    </p>
                    <RootLeftImgBox>
                        <img src={second} />
                    </RootLeftImgBox>
                </RootBox>
            </FlexAlignCenterJustifyCenterContainer>
            <button
                onClick={() => {
                    window.open(
                        'https://gabrielhollington.arkpiamuseum.com/',
                        '_blank'
                    );
                }}
            >
                Visit
            </button>
        </RootContainer>
    );
};

export default VirtualMsg;
