import React from 'react';
import {
    FooterRightContainer,
    TopContainer,
    TopInnerContainer,
} from './styles';
import {
    FlexAlignCenterJustifyCenterContainer,
    FlexColumn,
    FlexRowContainer,
    ImageWidth100ObjectFitContain,
} from '../container/styles';
// import Arkpia from '../../assets/arkpia_logo.png';

import twitter_logo from '../../assets/twitter_footer.png';
import instagram_logo from '../../assets/insta_footer.png';
import discord_logo from '../../assets/discord_footer.png';
import four_footer from '../../assets/four_footer.png';
import {
    FlexAlignCenterJustifyCenterContainerSpreadStyle,
    ImageContainer,
} from '../container';
import { BASE64_FOOTER_LOGO } from './base64ImgFooter';

const Footer = () => {
    return (
        <TopContainer>
            <TopInnerContainer>
                <div>
                    <ImageWidth100ObjectFitContain
                        style={{ width: '162px' }}
                        src={BASE64_FOOTER_LOGO}
                    />
                </div>
                <FlexAlignCenterJustifyCenterContainer
                    style={{
                        gap: '20px',
                    }}
                >
                    {logo.map((item, i) => {
                        return (
                            <div key={i}>
                                <a href={item.link} target='_blank'>
                                    <ImageContainer
                                        style={{
                                            width: '20px',
                                            height: '15px',
                                        }}
                                        src={item.src}
                                    />
                                </a>
                            </div>
                        );
                    })}
                </FlexAlignCenterJustifyCenterContainer>
                <FooterRightContainer>
                    <p style={{ textAlign: 'right', color: '#B8B8B8' }}>
                        ⓒ j&john all right reserved
                    </p>
                </FooterRightContainer>
            </TopInnerContainer>
        </TopContainer>
    );
};

let logo = [
    {
        id: 1,
        src: twitter_logo,
        link: 'https://twitter.com/i/flow/login?redirect_after_login=%2Farkpia',
    },
    {
        id: 2,
        src: instagram_logo,
        link: 'https://www.instagram.com/arkpia/',
    },
    {
        id: 3,
        src: discord_logo,
        link: 'https://discord.com/invite/arkpia',
    },
    {
        id: 4,
        src: four_footer,
        link: 'https://medium.com/@arkpia',
    },
];

export default Footer;
