import React, { useEffect, useRef, useState } from 'react';
import { WallCanvasPicture } from '../../page/doodleboard/styles';
import {
    CanvasImg,
    CanvasRenderImageBox,
    ThicknessRangeBar,
    ThicknessRootContainer,
    UndoBox,
} from './styles';
import { isMobileDevice } from '../../utils/isMobile';
import { FlexRowJustifyContentSpaceBetween } from '../container/styles';
import ThicknessTitle from '../../assets/Thickness_title.png';
import { ThicknessImgTitle } from '../nav/styles';
import * as FaIcons from 'react-icons/fa';
import './styles.css';
import Thickness from '../thickness';
import useCanvas from '../../hooks/useCanvas';

const Canvas = ({
    isModalOpen,
    RenderImg,
    CANVAS_REF,
    setColorState,
    colorState,
}) => {
    const [isMobile, setIsMobile] = useState(isMobileDevice());

    const {
        handleUndo,
        startDrawing,
        stopDrawing,
        handleChangeLineWidth,
        drawingHistory,
        thicknessSize,
        saveSnapshot,
        index,
        draw,
    } = useCanvas(CANVAS_REF, RenderImg, isMobile, colorState);

    useEffect(() => {
        const canvas = CANVAS_REF.current;

        const context = canvas.getContext('2d');
        //
        canvas.width = 321;
        canvas.height = 317;
        context.lineCap = 'round'; //line 종류
        context.lineJoin = 'round'; // line 꺽이는
        context.strokeStyle = colorState;
        context.lineWidth = thicknessSize;
        setColorState(context.strokeStyle);
        const image = new Image();
        image.src = RenderImg;
        image.onload = () => {
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };

        if (canvas) {
            canvas.addEventListener(
                isMobile ? 'touchstart' : 'mousedown',
                startDrawing
            );
            canvas.addEventListener(isMobile ? 'touchmove' : 'mousemove', draw);
            canvas.addEventListener(
                isMobile ? 'touchend' : 'mouseup',
                stopDrawing
            );
            canvas.addEventListener(
                isMobile ? 'touchcancel' : 'mouseout',
                stopDrawing
            );
        }
        return () => {
            canvas.removeEventListener(
                isMobile ? 'touchstart' : 'mousedown',
                startDrawing
            );
            canvas.removeEventListener(
                isMobile ? 'touchmove' : 'mousemove',
                draw
            );
            canvas.removeEventListener(
                isMobile ? 'touchend' : 'mouseup',
                stopDrawing
            );
            canvas.removeEventListener(
                isMobile ? 'touchcancel' : 'mouseout',
                stopDrawing
            );
        };
    }, [RenderImg]);

    return (
        <>
            <WallCanvasPicture
                isModalOpen={isModalOpen}
                cursor={colorState}
                ref={CANVAS_REF}
            />
            {/*<CanvasRenderImageBox isModalOpen={isModalOpen}>*/}
            {/*    /!*<CanvasImg src={RenderImg} />*!/*/}
            {/*</CanvasRenderImageBox>*/}

            <ThicknessRootContainer>
                <FlexRowJustifyContentSpaceBetween
                    style={{
                        width: '100%',
                        gap: '10px',
                        position: 'relative',

                        paddingBottom: '2%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: 1,
                            gap: '10px',
                            width: '100%',
                            position: 'absolute',
                        }}
                    >
                        <div>
                            <ThicknessImgTitle
                                src={ThicknessTitle}
                                alt={'thicknesstitle'}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <ThicknessRangeBar
                                min='1'
                                value={thicknessSize}
                                type='range'
                                max='100'
                                onInput={handleChangeLineWidth}
                            />
                        </div>
                        <div
                            onClick={() => {
                                handleUndo(CANVAS_REF);
                            }}
                            style={{
                                zIndex: isModalOpen ? '0' : '12341234123412343',
                            }}
                        >
                            <UndoBox>
                                <FaIcons.FaUndo style={{ cursor: 'pointer' }} />
                            </UndoBox>
                        </div>
                    </div>
                </FlexRowJustifyContentSpaceBetween>
            </ThicknessRootContainer>
        </>
    );
};

export default Canvas;
