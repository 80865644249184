import styled from 'styled-components';

export const RootFaQContainer = styled.div`
    padding: 30px 39px;
    max-width: 1070px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    @media ${({ theme }) => theme.device.tablet} {
        padding: 20px 10px;
    }
`;
