import React from 'react';
import { DashBoardImage } from './styles';
import styled from 'styled-components';

export const ImageRenderDashBoard = ({ mapRef, dashboardRender }) =>
    React.useMemo(
        () => (
            <>
                <DashBoardImage
                    style={{ width: '100%', height: 'calc(100vh - 30px)' }}
                    ref={mapRef}
                    useMap='#image-map'
                    src={dashboardRender.img}
                />
                <div>
                    <map name={'image-map'}>
                        {dashboardRender.render?.map((item, i) => {
                            return (
                                <AreaCustom
                                    title={item.title}
                                    shape='poly'
                                    key={i}
                                    alt={item.title}
                                    data-coords={item.coords}
                                    onClick={item.clicks}
                                />
                            );
                        })}
                    </map>
                </div>
            </>
        ),
        [dashboardRender.img]
    );
export const AreaCustom = styled.area`
    cursor: pointer;

    /* hover 시 그림자 효과 변경 */
`;
