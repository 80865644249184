import React, { useEffect, useRef, useState } from 'react';

import ImageMap from 'image-map';
import MainWall from '../../assets/Comp-1.gif';
import {
    AbsoluteCardBox,
    AbsoluteCardSecondBox,
    AbsoluteCardThirdBox,
    AbsoluteWallBox,
    EmoticonImg,
    FirstWallEmoticonBox,
    FiveWallEmoticonBox,
    FourWallEmoticonBox,
    ImageRelativeContainer,
    MainImage,
    SecondWallEmoticonBox,
    ThirdWallEmoticonBox,
} from './styles';

import {
    FlexAlignCenterJustifyCenterColumnContainer,
    MaxWidth1024Height100vh,
    Width100Container,
} from '../../components/container/styles';

import {
    FirstRowData,
    FourRowData,
    SecondRowData,
    ThirdRowData,
} from '../../components/constant/index';

import Carousel from '../../components/carousel';
import MainNav from '../../components/nav';
import { WallEmoticonContainer } from '../../components/container';
import { wallGifImgCoords } from '../../constant/image';
import Artist from '../../components/artist';
import Introduce from '../../components/introduce';
import CharacterIntroduce from '../../components/characterIntroduce';
import MainDashBoard from '../../components/maindashboard';
import { Link } from 'react-scroll';
import useCoords from '../../hooks/useCoords';
import { useLocation, useNavigate } from 'react-router-dom';
import Picture from '../../components/carouseldownpicture';
import RepresentativeArtWorks from '../../components/representativeArtworks ';
import FaqPage from '../faq';
import subBanner from '../../assets/v2/중간배너.jpg';

const Home = () => {
    const {
        dashboardRender,
        setDashboardRender,
        sectionOne,
        sectionTwo,
        sectionThree,
        sectionFour,
        ColImgCoords,
        RowImgCoords,
        isModalOpen,
        setIsModalOpen,
    } = useCoords();

    const path = useLocation();
    const navigate = useNavigate();

    const { firstRow } = FirstRowData();
    const { secondRow } = SecondRowData();
    const { thirdRow } = ThirdRowData();
    const { fourRow } = FourRowData();

    return (
        <Width100Container>
            <MainDashBoard
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                RowImgCoords={RowImgCoords}
                ColImgCoords={ColImgCoords}
                dashboardRender={dashboardRender}
                setDashboardRender={setDashboardRender}
            />

            <div ref={sectionOne}>
                <div style={{ marginTop: '-5px' }}>
                    <img
                        style={{ height: '100%', width: '100%' }}
                        src={subBanner}
                    />
                </div>
                <Introduce />
            </div>
            <div ref={sectionTwo}>
                <CharacterIntroduce />
            </div>
            <div>
                <Carousel rowData={firstRow} />
                <Carousel index={2} rowData={secondRow} />
                <Carousel rowData={thirdRow} />
                <Carousel index={4} rowData={fourRow} />
            </div>
            <div>
                <Picture />
            </div>
            <div ref={sectionThree}>
                <Artist />
            </div>
            <div>
                <RepresentativeArtWorks />
            </div>
            <div ref={sectionFour}>
                <FaqPage />
            </div>
        </Width100Container>
    );
};

export default Home;
