import styled from 'styled-components';

const Bold20NotoSansFont = styled.p`
    font-family: 'Noto Sans', Baolo;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;

const FontSize10 = styled.p`
    font-family: 'Noto Sans', sans-serif;
`;

export const FontSize24Montserrat = styled.p`
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    @media ${({ theme }) => theme.device.tablet} {
        font-size: 13px;
    }
    @media ${({ theme }) => theme.device.mobileS} {
        font-size: 10px;
    }
`;
export const FontSize18Montserrat = styled.span`
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media ${({ theme }) => theme.device.tablet} {
        font-size: 13px;
    }
    @media ${({ theme }) => theme.device.mobileS} {
        font-size: 9px;
    }
`;

export const FontSize25Montserrat = styled.span`
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media ${({ theme }) => theme.device.tablet} {
        font-size: 13px;
    }
    @media ${({ theme }) => theme.device.mobileS} {
        font-size: 9px;
    }
`;

// export const FontSize15_FE8208_Montserrat = styled.span`
//     color: #fe8208;
//     font-family: Montserrat;
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: normal;
//     @media ${({ theme }) => theme.device.tablet} {
//         font-size: 13px;
//     }
//     @media ${({ theme }) => theme.device.mobileS} {
//         font-size: 9px;
//     }
// `;
export const FaQBox = styled.div`
    border-radius: 4px;
    background: #fe8208;
    padding: 5px 7px;
    span {
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 13px;
        }
        @media ${({ theme }) => theme.device.mobileS} {
            font-size: 9px;
        }
    }
`;

export const FaQDetailFontSize15 = styled.pre`
    color: #fe8208;
    margin: 8px 0px 26px 0px;
    font-family: Montserrat;
    white-space: pre-wrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;

    @media ${({ theme }) => theme.device.tablet} {
        font-size: 13px;
    }
    @media ${({ theme }) => theme.device.mobileS} {
        font-size: 10px;
    }
`;
