import styled from 'styled-components';

export const IntroduceRootBox = styled.div`
    width: 100%;
    background: #8c52ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px;
    margin-top: -4.7px;
`;

export const IntroduceImg = styled.img`
    max-width: 314px;
    height: 314px;
    object-fit: contain;
`;

export const IntroduceImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const IntroduceContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`;

export const IntroduceFlexBox = styled.div`
    display: flex;
    flex-direction: row;
    @media ${({ theme }) => theme.device.tablet} {
        //width: 55%;
        //height: 65%;
        flex-direction: column;
    }
`;
export const IntroduceFontBox = styled.div`
    max-width: 575px;
    padding: 20px;
    h3 {
        color: #fff;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px 0px 10px 0px;
    }

    p {
        margin-bottom: 10px;
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

export const ButtonFlexGap10px = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    @media ${({ theme }) => theme.device.tablet} {
        flex-direction: column;
        gap: 10px;
    }
`;
