import styled from 'styled-components';
import Bg from '../../assets/Background.png';
import { Width100ImgObjectFitContain } from '../../components/nav/styles';
import { PositionAbsoluteDisplayFlex } from '../../components/container/styles';
import ResponsiveBg from '../../assets/wall_behind_responsive.png';
export const DoodleBoardBackgroundImage = styled.div`
    width: 100%;
    max-width: 1024px;

    background-color: brown;
    height: 100%;
    position: relative;
`;

export const DoodleBoardImg = styled.div`
    width: 100%;
    overflow: hidden;
    //min-height: 100vh;
    @media ${({ theme }) => theme.device.tablet} {
        height: 100%;
    }
    background-image: url(${Bg});
    background-repeat: no-repeat;
    background-image: ${(props) => props.responsesivemaxwidth < 1024 && 'none'};
    display: flex;
    justify-content: center;

    background-size: cover;
`;
export const ResponsiveMaxWidth1024 = styled.div`
    max-width: ${(props) =>
        props.responsesivemaxwidth < 1024 ? '640px' : '640px'};
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
`;

export const WallBehindBackgroundBlack = styled.div`
    width: 100%;
    margin: 60px 0px 0px 0px;
    @media ${({ theme }) => theme.device.tablet} {
        //margin: 40px 0px 0px 0px;
    }
    @media ${({ theme }) => theme.device.mobileL} {
        margin: 20px 0px 0px 0px;
    }
    border-radius: 90px 90px 0px 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
    height: auto;
`;

export const WallTopContents = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
`;

export const WallTopFlexJustifySpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const WallTopContentsElement = styled.div`
    position: relative;

    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const WallTopLeft = styled(WallTopContentsElement)`
    position: absolute;
    top: -14%;
    bottom: 0;
    max-width: 21%;
    cursor: pointer;
    height: 30%;
`;
export const WallTopRight = styled(WallTopContentsElement)`
    top: -8.45%;
    cursor: pointer;
    right: -2%;
    position: absolute;
    bottom: 0;
    max-width: 23.99%;
    height: 30%;
`;

export const WallCanvasPictureBox = styled.div`
    position: absolute;

    width: 57%;

    border-radius: 8px;
    border: 5px solid #000;
    margin-left: 28px;

    height: 70%;
`;

export const WallCanvasPicture = styled.canvas`
    position: absolute;
    z-index: ${(props) => (props.isModalOpen ? 0 : 341325321523512352)};
    width: 100%;

    cursor: ${(props) =>
        props.cursor ? `url(${props.cursor}), auto` : 'default'};
`;

export const WallBehindPicture = styled(Width100ImgObjectFitContain)``;

export const PaintContainer = styled.div`
    display: flex;
    max-width: 569px;

    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const GridBoxContainer = styled.div`
    display: grid;
    gap: 20px;
    padding: 30px 20px;
    width: 100%;

    grid-template-columns: repeat(4, 1fr);
`;

export const GridBox = styled.div`
    div {
        width: 100%;
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }
`;

export const GridImg = styled.img`
    width: 100%;
    height: auto;
    object-fit: scale-down;
`;

export const PaintBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 13;

    flex-direction: row;
    max-width: 591px;
    width: 100%;
`;

export const PaintImg = styled.img`
    //max-width: 86vw;
    //max-height: 90px;
    max-width: 10vw;
    object-fit: contain;
    cursor: pointer;
`;

export const FallenPaintBox = styled(PositionAbsoluteDisplayFlex)`
    bottom: -20%;
    width: 100%;

    transform: translateY(-50%, 50%); /* 엘리먼트를 수직 방향으로 이동시킴 */

    //background: red;
    img {
        width: 100%;
        object-fit: contain;
    }
`;
