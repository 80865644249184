import React from 'react';
import { RootBox } from '../../components/virtualMsg/styles';
import { RootFaQContainer } from './stlyes';
import {
    CharacterLabelFontSize25,
    FaQBoxComponent,
} from '../../components/label';

const FaqPage = () => {
    return (
        <RootFaQContainer>
            <div style={{ width: '100%' }}>
                <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                    <CharacterLabelFontSize25 text={'FAQ'} />
                </div>
                <FaQBoxComponent
                    detail={
                        'Get ready for an epic adventure with 10 captivating characters!' +
                        '\n' +
                        'Each character boasts a unique personality and backstory, intertwining in a thrilling narrative that will keep' +
                        '\n' +
                        "you on the edge of your seat Gabriel Hollington's Playful Spirit Comes to Life in His Artwork"
                    }
                    text={'Q. Gabriel Hollington’s NFTs'}
                />
                <FaQBoxComponent
                    detail={
                        'Cig Thief - 200       Coolboy - 280 \nLenny - 480     Jimmy - 480 \nJohnny - 480   Partyboy - 480\nRadcat - 480   Sunny - 480\nFluffy - 320     Shawty - 320'
                    }
                    text={'Q. Supply - 4,000'}
                />
                <FaQBoxComponent
                    detail={
                        'Date : May 30  \nPrice : Phase1 ( Arkpia Pass Holder) - 2Aptos, Phase2 ( FCFS Art Connect Ticket Holder ) - 3Aptos'
                    }
                    text={'Q. Mint '}
                />
                <FaQBoxComponent
                    detail={
                        "You must obtain an Art Connect ticket. You can participate as FCFS WL.\nFor information on receiving an art connect ticket, check out Akpia's official Twitter and Discord."
                    }
                    text={"Q. How can I get Gabriel Hollington's WI? "}
                />
            </div>
        </RootFaQContainer>
    );
};

export default FaqPage;
