import React from 'react';
import {
    CharacterPageFlex,
    ContentFlexBox,
    Flex,
    GridBox,
    ImageBox,
    ImageRootBoxCharacter,
    NumberBox,
    NumberImg,
    RenderImageRootBox,
    RootBoxImage,
    SubTitle,
} from './styles';
import RootImg from '../../assets/faq/person.png';

import { CharacterLabelFontSize18, CharacterLabelFontSize24 } from '../label';
import { characterCard } from './label';
import CharacterIntroduceImg from '../../assets/v2/whoarethey.png';
import { FlexAlignCenterJustifyCenterContainer } from '../container/styles';

const CharacterIntroduce = () => {
    return (
        <CharacterPageFlex>
            <ImageRootBoxCharacter>
                <RootBoxImage src={CharacterIntroduceImg} />
            </ImageRootBoxCharacter>
            <FlexAlignCenterJustifyCenterContainer>
                <GridBox>
                    {characterCard.map((item, i) => {
                        return (
                            <ImageBox key={i}>
                                <img
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                    src={item.src}
                                    alt={''}
                                />
                            </ImageBox>
                        );
                    })}
                </GridBox>
            </FlexAlignCenterJustifyCenterContainer>
            {/*<ImageRootBoxCharacter>*/}
            {/*    <RootBoxImage src={RootImg} />*/}
            {/*</ImageRootBoxCharacter>*/}
            {/*<RenderImageRootBox>*/}
            {/*    {RenderCharacterIntroduce.map((item, i) => {*/}
            {/*        return (*/}
            {/*            <NumberBox key={i}>*/}
            {/*                <div style={{ width: '41px', height: '41px' }}>*/}
            {/*                    <NumberImg src={item.src} />*/}
            {/*                </div>*/}
            {/*                <Flex>*/}
            {/*                    <ContentFlexBox>*/}
            {/*                        {item.title}*/}
            {/*                        <SubTitle>{item.subtitle}</SubTitle>*/}
            {/*                    </ContentFlexBox>*/}
            {/*                    <div>*/}
            {/*                        <CharacterLabelFontSize18*/}
            {/*                            text={item.content}*/}
            {/*                        />*/}
            {/*                    </div>*/}
            {/*                </Flex>*/}
            {/*            </NumberBox>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</RenderImageRootBox>*/}
        </CharacterPageFlex>
    );
};

export default CharacterIntroduce;
