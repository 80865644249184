import React, { useEffect, useRef, useState } from 'react';
import {
    CommonImg,
    ImageHeight25Contain,
    MainNavContainer,
    NavBarRouteBox,
} from './styles';
import ArtImg from '../../assets/1th_qa_impor_img/qa_nav_1.png';

import X from '../../assets/1th_qa_impor_img/qa_nav_x.png';
import { Link, useNavigate } from 'react-router-dom';
import { FlexAlignCenterJustifyCenterContainer } from '../container/styles';

import GabrielImg from '../../assets/1th_qa_impor_img/qa_nav_2.png';

import HamBurGer from '../hambuger';
import useWindow from '../../hooks/useWindow';
const MainNav = () => {
    const { maxWidth } = useWindow();
    const navigation = useNavigate();
    const hamburgerRef = useRef(); // 1. ref 를 만들어주고

    const [isShowHamBurGer, setIsShowHamBurGer] = useState(false);

    const marginMaxWidth = maxWidth <= 650 ? '6px' : '30px';

    return (
        <MainNavContainer ref={hamburgerRef}>
            <FlexAlignCenterJustifyCenterContainer>
                <div
                    style={{
                        height: '25px',
                        display: 'flex',
                    }}
                >
                    <a href='https://arkpia.com/' target='_blank'>
                        <ImageHeight25Contain src={ArtImg} alt={'ArtImg'} />
                    </a>
                    <CommonImg
                        style={{ width: '25px', height: '25px' }}
                        src={X}
                        alt={'X'}
                    />

                    <NavBarRouteBox
                        onClick={() => {
                            navigation('/');
                        }}
                        style={{ marginRight: marginMaxWidth }}
                    >
                        <ImageHeight25Contain src={GabrielImg} />
                    </NavBarRouteBox>
                    {/*<NavBarRouteBox>*/}
                    {/*    <HamBurGer*/}
                    {/*        hamburgerRef={hamburgerRef}*/}
                    {/*        isShowHamBurGer={isShowHamBurGer}*/}
                    {/*        setIsShowHamBurGer={setIsShowHamBurGer}*/}
                    {/*        maxwidthResponsive={maxWidth}*/}
                    {/*    />*/}
                    {/*</NavBarRouteBox>*/}
                </div>
            </FlexAlignCenterJustifyCenterContainer>
        </MainNavContainer>
    );
};

export default MainNav;
