import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Home from './page/home';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import theme from './theme';
import {
    FlexAlignCenterJustifyCenterColumnContainer,
    MaxWidth1024Height100vh,
    RootContainer,
} from './components/container/styles';
import MainNav from './components/nav';
import React, { useEffect } from 'react';
import Footer from './components/footer';
import DoodleBoardPage from './page/doodleboard';
import useCoords from './hooks/useCoords';

function App() {
    const GlobalStyle = createGlobalStyle` 
  body {
    font-family: 'Noto Sans', sans-serif; 
    padding: 0; 
    margin: 0;
    background: #fff;
  }

  a{
    margin:0;
    padding:0;
  }
  div, p, span, h1 {
    margin: 0;
    box-sizing: border-box; 

    ::-webkit-scrollbar {
      display: none; 
    }
  }

  button, input {
    outline: none; 
    border: none; 
  }

  button {
    cursor: pointer; 
  }
  img{
    -webkit-user-drag: none; //이미지 잔상 없애기 추가 
  }
  `;

    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />

                <RootContainer>
                    <FlexAlignCenterJustifyCenterColumnContainer>
                        <Router>
                            <MainNav />

                            <Routes>
                                <Route
                                    path='/'
                                    element={
                                        <Navigate
                                            to='/gabriel'
                                            replace={true}
                                        />
                                    }
                                />
                                <Route path='/gabriel' element={<Home />} />

                                <Route
                                    path='/graffiti-wall'
                                    element={<DoodleBoardPage />}
                                />
                            </Routes>
                        </Router>
                    </FlexAlignCenterJustifyCenterColumnContainer>
                </RootContainer>
                <Footer />
            </ThemeProvider>
        </>
    );
}

export default App;
