import React from 'react';
// import first from '../../assets/charactercomponents/Vector.png';
// import second from '../../assets/charactercomponents/Vector (1).png';
// import third from '../../assets/charactercomponents/Vector (2).png';
// import four from '../../assets/charactercomponents/Vector (3).png';
// import five from '../../assets/charactercomponents/Vector (4).png';
// import six from '../../assets/charactercomponents/Vector (5).png';
import { CharacterLabelFontSize24 } from '../label';
import Character1 from '../../assets/characterIndroduce/입면도_2-01.png';
import Character2 from '../../assets/characterIndroduce/입면도_2-02.png';
import Character3 from '../../assets/characterIndroduce/입면도_2-03.png';
import Character4 from '../../assets/characterIndroduce/입면도_2-04.png';
import Character5 from '../../assets/characterIndroduce/입면도_2-05.png';
import Character6 from '../../assets/characterIndroduce/cool.svg';
import Character7 from '../../assets/characterIndroduce/party.svg';
import Character8 from '../../assets/characterIndroduce/rad.svg';
import Character9 from '../../assets/characterIndroduce/입면도_2-09.png';
import Character10 from '../../assets/characterIndroduce/입면도_2-10.png';

// export const RenderCharacterIntroduce = [
//     {
//         id: 1,
//         src: first,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#9D83FF' }}
//                     text={'Cig Thief'}
//                 />
//             </>
//         ),
//         subtitle: 'The Sneaky Cigarette Thief',
//         content:
//             "The cheeky dog, Cig Thief, who never buys his own cigarettes – he's the master of bumming smokes off his friends. He lived in East London, enjoying records and burgers when his owner was away.",
//     },
//     {
//         id: 2,
//         src: second,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#FD9F55' }}
//                     text={'Cool Boy'}
//                 />
//             </>
//         ),
//         subtitle: 'Hip-hop Band Leader',
//         content:
//             'Exuding coolness with a stylish flair, Cool Boy leads "The Cool Boys" hip-hop band, admired by all ages, and causing a sensation with his every move. He\'s the heartthrob everyone adores.',
//     },
//     {
//         id: 3,
//         src: third,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#99BDD5' }}
//                     text={'Shawty'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'&'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#FE8208' }}
//                     text={'Lenny'}
//                 />
//             </>
//         ),
//         subtitle: 'The Mischief Makers',
//         content:
//             "Sneaky Downtown's dynamic duo! Shawty and Lenny speed through the streets in their sports car, blasting music, and sometimes causing a bit of trouble.",
//     },
//     {
//         id: 4,
//         src: four,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#FE8208' }}
//                     text={'Jimmy'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'&'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'Johnny'}
//                 />
//             </>
//         ),
//         subtitle: 'The Hip-hop DJ Gurus',
//         content:
//             'As members of a hip-hop band running a pirate radio station, They may spread tall tales, but their sharp wit and impressive song selections never fail to entertain Sneaky Downtown.',
//     },
//     {
//         id: 5,
//         src: five,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#FFBE00' }}
//                     text={'Party Boy'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'&'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#FE8208' }}
//                     text={'Rad Cat'}
//                 />
//             </>
//         ),
//         subtitle: 'The Ultimate Party Duo',
//         content:
//             "With Party Boy's record shop, the most romantic spot in Sneaky Downtown, and his adorable skater girlfriend Rad Cat throwing the wildest parties in town.",
//     },
//     {
//         id: 6,
//         src: six,
//         title: (
//             <>
//                 <CharacterLabelFontSize24
//                     style={{ color: '#F2A146' }}
//                     text={'Sunny'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'&'}
//                 />
//                 <CharacterLabelFontSize24
//                     style={{ color: '#000' }}
//                     text={'Fluffy'}
//                 />
//             </>
//         ),
//         subtitle: 'Graffiti-Loving Artists',
//         content:
//             "Under the night's cloak, this artistic duo transforms Sneaky Downtown's landscape. Sunny, the graffiti-loving rabbit, and Fluffy, the clever cat, create an enchanting wonderland.",
//     },
// ];

export let characterCard = [
    { id: 1, src: Character1 },
    { id: 2, src: Character6 },
    { id: 3, src: Character3 },
    { id: 4, src: Character2 },
    { id: 5, src: Character5 },
    { id: 6, src: Character4 },
    { id: 7, src: Character7 },
    { id: 8, src: Character8 },
    { id: 9, src: Character9 },
    { id: 10, src: Character10 },
];
