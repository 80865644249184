import styled from 'styled-components';

export const HamBurgerContainer = styled.div`
    z-index: 9999999999999999999999;
    position: absolute;
    width: 100%;
    display: flex;
    top: 30px;
    right: 0;
    height: 116px;

    bottom: 0;
`;
