import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashBoardImgRow from '../assets/faq/메인이미지-수정2 1.jpg';

const useCoords = () => {
    const sectionOne = useRef(null);
    const sectionTwo = useRef(null);
    const sectionThree = useRef(null);
    const sectionFour = useRef(null);
    const navigate = useNavigate();
    const path = useLocation();
    let RowImgCoords = [
        {
            title: 'modal',
            coords: '87,587,454,618,498,104,126,73',
            clicks: () => handleModalClick(),
        },
        {
            title: 'artist',
            coords: '529,316,735,339,763,102,550,81',
            clicks: () => scrollToSection(sectionThree),
        },
        {
            title: 'visitsneaky',
            coords: '654,362,715,764,1334,682,1259,266',
            clicks: () => {
                window.open(
                    'https://gabrielhollington.arkpiamuseum.com/',
                    '_blank'
                );
            },
        },
        {
            title: 'who are they',
            coords: '742,789,767,874,1353,818,1344,694',
            clicks: () => scrollToSection(sectionTwo),
        },
        {
            title: 'faq',
            coords: '388,815,408,1098,727,1100,715,815',
            clicks: () => scrollToSection(sectionFour),
        },
        {
            title: 'doodleboard',
            coords: '1538,711,1502,1085,1800,1113,1831,735',
            clicks: () => navigate('/graffiti-wall'),
        },
    ];

    let ColImgCoords = [
        {
            title: 'modal',
            coords: '94,90,451,101,436,596,83,588',
            clicks: () => handleModalClick(),
        },
        {
            title: 'artist',
            coords: '752,111,936,82,969,299,768,322',
            clicks: () => scrollToSection(sectionThree),
        },
        {
            title: 'visitsneaky',
            coords: '324,679,758,609,809,910,368,966',
            clicks: () => {
                window.open(
                    'https://gabrielhollington.arkpiamuseum.com/',
                    '_blank'
                );
            },
        },
        {
            title: 'who are they',
            coords: '367,989,801,919,809,1012,389,1051',
            clicks: () => scrollToSection(sectionTwo),
        },
        {
            title: 'faq',
            coords: '66,1260,354,1253,449,1506,72,1519',
            clicks: () => scrollToSection(sectionFour),
        },
        {
            title: 'doodleboard',
            coords: '714,1034,934,1016,970,1312,737,1323',
            clicks: () => navigate('/graffiti-wall'),
        },
    ];

    const [dashboardRender, setDashboardRender] = useState({
        img: DashBoardImgRow,
        render: RowImgCoords,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalClick = useCallback(() => {
        setIsModalOpen(true);
    }, [isModalOpen]);

    const scrollToSection = useCallback((elementRef) => {
        if (elementRef.current !== null) {
            const offset = elementRef.current.offsetTop;
            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    }, []);

    return {
        sectionOne,
        sectionTwo,

        isModalOpen,
        setIsModalOpen,
        sectionThree,
        dashboardRender,
        ColImgCoords,
        RowImgCoords,
        setDashboardRender,
        sectionFour,
    };
};

export default useCoords;
