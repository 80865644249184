import styled from 'styled-components';

export const TitleImg = styled.img`
    width: 100%;
`;

export const ImageRelativeContainer = styled.div`
    //width: 100%;
    max-width: 640px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    overflow: hidden;
`;

export const MainImage = styled.img`
    //min-width: 100%;
    //object-fit: contain;
    height: 100%;
    object-fit: contain;

    width: 100%;
    max-width: 640px;
    //max-height: 100vw;
    //max-width: 100%;/**/
`;
export const AbsoluteCardBox = styled.div`
    //max-width: 1024px;
    //text-align: center;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const AbsoluteCardSecondBox = styled.div`
    position: absolute;
    max-width: 1024px;

    text-align: center;
    top: 76%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;

    flex-shrink: 0;
    width: 100%;

    background: white;
`;
export const AbsoluteCardThirdBox = styled.div`
    position: absolute;
    max-width: 1024px;
    padding: 0px 30px;

    text-align: center;
    top: 86%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;

    background: white;
`;
export const AbsoluteWallBox = styled.div`
    position: absolute;
    max-width: 1024px;
    padding: 0px 30px;
    height: 600px;

    top: 30%;

    flex-shrink: 0;
    width: 100%;
    z-index: -9939149518589157918371893;
    //
    //background: rgba(255, 0, 0, 0.5);
`;

export const CardBox = styled.div``;

export const EmoticonImg = styled.img`
    max-width: 22%;
`;

export const FirstWallEmoticonBox = styled.div`
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: center;
`;

export const SecondWallEmoticonBox = styled.div`
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
export const ThirdWallEmoticonBox = styled.div`
    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export const FourWallEmoticonBox = styled.div`
    div {
        margin-top: -10px;
        margin-left: 10px;
        @media ${({ theme }) => theme.device.tablet} {
            margin-top: 20px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export const FiveWallEmoticonBox = styled.div``;

export const WallBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    //min-width: 240px;
    max-width: 1024px;
    //height: 100vh;
    width: 100%;
`;
