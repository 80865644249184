import styled from 'styled-components';

export const CanvasImg = styled.img`
    width: 100%;
`;

export const ThicknessRootContainer = styled.div`
    display: flex;
    //flex-direction: column;
    bottom: -17%;
    //background: red;
    align-items: center;
    //width: 100%;
    //height: auto;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    //background: red;
    width: 100%;

    max-width: 600px;
    @media ${({ theme }) => theme.device.medium} {
        //bottom: -60px;
        //height: auto;
    }
`;
export const CanvasRenderImageBox = styled.div`
    //width: 100%;
    z-index: ${(props) => (props.isModalOpen ? 0 : 1)};
    //background: red;
    //border-radius: 10px;
    //width: 101.66%;
    //height: 105.15%;

    //border: 10px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    //right: 4px;
    left: -4px;
`;

export const ThicknessRangeBar = styled.input.attrs({ type: 'range' })`
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: black; /* 조절 바의 배경색을 검은색으로 설정 */
    outline: none;
    margin: 10px 0;
    position: relative;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #8c52ff;
        cursor: pointer;
        position: relative; /* 그림자를 위한 relative 포지션 */
        z-index: 1; /* 그림자를 위해 핸들을 상위로 올립니다. */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border: none;
        border-radius: 50%;
        background: #8c52ff;
        cursor: pointer;
        position: relative;
        z-index: 1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* 그림자 스타일을 추가합니다. */
    }
`;

export const UndoBox = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${({ theme }) => theme.device.mobileXL} {
        width: 22px;
        height: 22px;
    }
`;
