import React, { useCallback, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import {
    CarouselImageBox,
    CarouselStyleContainer,
    ImageCarousel,
} from './style';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import NextImg from '../../assets/img/Group 129.png';
import styled from 'styled-components';

const CustomCarousel = ({ rowData, index }) => {
    const slickRef = useRef(null);

    const previous = useCallback(() => slickRef.current.slickPrev(), []);
    const next = useCallback(() => slickRef.current.slickNext(), []);
    const settings = {
        className: 'center', // 슬라이더의 클래스 이름
        centerMode: true, // 현재 슬라이드를 중앙에 배치
        infinite: true, // 무한 슬라이드
        slidesToShow: 7, // 한 번에 보여줄 슬라이드 수
        // slidesToScroll: 1,
        autoplay: true, // 자동 재생
        autoplaySpeed: index % 2 == 0 ? 2000 : 4000, // 자동 재생 속도 (밀리초 단위)
        rtl: index === 2 || (index === 4 && true),
        nextArrow: <NextArrow onClick={next} />,
        prevArrow: <PrevArrow onClick={previous} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <CarouselStyleContainer>
            <StyledSlider ref={slickRef} style={{}} {...settings}>
                {rowData?.map((item, i) => (
                    <div className='item'>
                        <CarouselImageBox key={i}>
                            <ImageCarousel src={item?.src} alt={''} />
                        </CarouselImageBox>
                    </div>
                ))}
            </StyledSlider>
        </CarouselStyleContainer>
    );
};

export default CustomCarousel;

const StyledSlider = styled(Slider)`
    position: relative;

    .slick-slide div {
        //슬라이더  컨텐츠
        cursor: pointer;
    }
`;
