import React, { useEffect, useState, useRef, useCallback } from 'react';
import { DashBoardImage, DashBoardImageBox } from './styles';
import DashBoardImgRow from '../../assets/faq/메인이미지-수정2 1.jpg';
import DashBoardImgColumn from '../../assets/faq/메인이미지-수정2_2 1.jpg';
import ImageMap from 'image-map';
import CustomModal from '../modal/CustomModal';
import styled from 'styled-components';
import { ImageRenderDashBoard } from './DashBoardUseMemo';
const MainDashBoard = ({
    dashboardRender,
    setDashboardRender,
    RowImgCoords,
    ColImgCoords,
    isModalOpen,
    setIsModalOpen,
}) => {
    const mapRef = useRef(null);
    const [maxWidthWindow, setMaxWidthWindow] = useState(window.innerWidth);
    const [imageHeight, setImageHeight] = useState(0);

    const handleResize = useCallback(() => {
        const windowWidth = window.innerWidth;
        setMaxWidthWindow(windowWidth);

        if (windowWidth < 1024) {
            setDashboardRender({
                img: DashBoardImgColumn,
                render: ColImgCoords,
            });
        } else {
            setDashboardRender({
                img: DashBoardImgRow,
                render: RowImgCoords,
            });
        }
    }, [maxWidthWindow]);

    //
    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        ImageMap('img[usemap]');
    }, [dashboardRender]);

    return (
        <>
            <CustomModal
                isModalOpen={isModalOpen}
                name={'newspaper'}
                setIsModalOpen={setIsModalOpen}
            />
            <DashBoardImageBox style={{ width: '100%' }}>
                <ImageRenderDashBoard
                    mapRef={mapRef}
                    dashboardRender={dashboardRender}
                />
            </DashBoardImageBox>
        </>
    );
};

export default MainDashBoard;
