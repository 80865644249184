import styled from 'styled-components';

export const IFrameStyle = styled.iframe`
    width: 100%;
    height: 60vw;
`;

export const IFrameBox = styled.div`
    width: 100%;
`;

export const ArtistRootContainer = styled.div`
    padding: 40px;
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ArtistProfileImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
export const ArtistSection2 = styled.div`
    max-width: 1070px;
    margin-bottom: 40px;
    h4 {
        color: #fff;
        text-align: justify;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p {
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span {
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
    }
`;

export const IconBox = styled.a`
    width: 40px;
    height: 40px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
`;

export const DisplayFlexArtist = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 40px;
    width: 100%;
`;
export const IconImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
`;
export const ArtistSection = styled.div`
    color: white;

    max-width: 455px;
    @media ${({ theme }) => theme.device.tablet} {
        max-width: 100%;
    }
    h3 {
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 30px;
        }
        color: #fff;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0px 0px 1px 0px;
    }

    h4 {
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 20px;
        }
        margin: 0px 0px 20px 0px;
        color: #fe8208;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p {
        @media ${({ theme }) => theme.device.tablet} {
            font-size: 18px;
        }
        color: #fff;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px 0px 20px 0px;
    }
`;

export const ArtistSectionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    @media ${({ theme }) => theme.device.tablet} {
        flex-direction: column;
    }
`;

export const ArtistImgBox = styled.div`
    @media ${({ theme }) => theme.device.tablet} {
        width: 100%;
    }
`;
