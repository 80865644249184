import styled from 'styled-components';

export const MainNavContainer = styled.div`
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 5px 0px 0px 0px;
`;

export const CommonImg = styled.img`
    max-width: 110px;
    width: 100%;
`;
export const Width100ImgObjectFitContain = styled.img`
    object-fit: contain;
    width: 100%;
`;

export const ThicknessImgTitle = styled.img`
    max-width: 95px;
    width: 100%;
`;

export const NavBarRouteBox = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
`;

export const ImageHeight25Contain = styled(CommonImg)`
    object-fit: contain;
    height: 25px;
    cursor: pointer;
`;
