import DoodleBoard from '../../assets/1th_qa_impor_img/doodleboard_nav.png';
import StoryImg from '../../assets/1th_qa_impor_img/story_nav.png';
import FaqImg from '../../assets/1th_qa_impor_img/faq_nav.png';
import ArtistImg from '../../assets/1th_qa_impor_img/artist_nav.png';
import GabrielImg from '../../assets/1th_qa_impor_img/qa_nav_2.png';
export const NavBar = [
    { id: 1, src: StoryImg, route: '/story' },
    { id: 2, src: DoodleBoard, route: '/doodleboard' },
    { id: 3, src: ArtistImg, route: 'artist' },
    { id: 4, src: FaqImg, route: 'faq' },
];
export const FirstRowData = () => {
    const firstRow = [];
    for (let i = 1; i <= 70; i++) {
        const item = {
            id: i,
            src: `/assets/카로셀/1/${i}.png`,
        };
        firstRow.push(item);
    }

    return { firstRow };
};

export const SecondRowData = () => {
    const secondRow = [];
    for (let i = 1; i <= 70; i++) {
        const item = {
            id: i,
            src: `/assets/카로셀/2/${i}.png`,
        };
        secondRow.push(item);
    }

    return { secondRow };
};
export const ThirdRowData = () => {
    const thirdRow = [];
    for (let i = 1; i <= 70; i++) {
        const item = {
            id: i,
            src: `/assets/카로셀/3/${i}.png`,
        };
        thirdRow.push(item);
    }

    return { thirdRow };
};
export const FourRowData = () => {
    const fourRow = [];
    const usedNumbers = new Set(); // 이미 사용된 숫자를 추적하는 Set

    while (fourRow.length < 30) {
        // fourRow 배열이 30개의 항목을 가질 때까지 반복
        const randomNum = Math.floor(Math.random() * 30) + 1; // 1에서 30까지 랜덤 숫자 생성
        if (!usedNumbers.has(randomNum)) {
            // 이 숫자가 아직 사용되지 않았다면
            usedNumbers.add(randomNum); // Set에 숫자를 추가
            const item = {
                id: fourRow.length + 1, // 아이디를 현재 배열 길이 + 1로 설정
                src: `/assets/카로셀/carouselLastRowImg/${randomNum}.png`,
            };
            fourRow.push(item); // 배열에 아이템 추가
        }
    }

    return { fourRow }; // 결과 배열 반환
};
// export const SecondRowData = [
//     {
//         id: 1,
//         src: '/assets/img/2번째 줄-1.jpg',
//     },
//     {
//         id: 2,
//         src: '../../assets/img/2번째 줄-02.jpg',
//     },
//     {
//         id: 3,
//         src: '../../assets/img/2번째 줄-03.jpg',
//     },
//     {
//         id: 4,
//         src: '../../assets/img/2번째 줄-04.jpg',
//     },
//     {
//         id: 5,
//         src: '../../assets/img/2번째 줄-05.jpg',
//     },
//     {
//         id: 6,
//         src: '../../assets/img/2번째 줄-06.jpg',
//     },
//     {
//         id: 7,
//         src: '../../assets/img/2번째 줄-07.jpg',
//     },
//     {
//         id: 8,
//         src: '../../assets/img/2번째 줄-08.jpg',
//     },
//     {
//         id: 9,
//         src: '../../assets/img/2번째 줄-09.jpg',
//     },
//     {
//         id: 10,
//         src: '../../assets/img/2번째 줄-10.jpg',
//     },
//     {
//         id: 11,
//         src: '../../assets/img/2번째 줄-11.jpg',
//     },
//     {
//         id: 12,
//         src: '../../assets/img/2번째 줄-12.jpg',
//     },
//     {
//         id: 13,
//         src: '../../assets/img/2번째 줄-13.jpg',
//     },
//     {
//         id: 14,
//         src: '../../assets/img/2번째 줄-14.jpg',
//     },
//     {
//         id: 15,
//         src: '../../assets/img/2번째 줄-15.jpg',
//     },
//     {
//         id: 16,
//         src: '../../assets/img/2번째 줄-16.jpg',
//     },
//     {
//         id: 17,
//         src: '../../assets/img/2번째 줄-17.jpg',
//     },
//     {
//         id: 18,
//         src: '../../assets/img/2번째 줄-18.jpg',
//     },
//     {
//         id: 19,
//         src: '../../assets/img/2번째 줄-19.jpg',
//     },
//     {
//         id: 20,
//         src: '../../assets/img/2번째 줄-20.jpg',
//     },
// ];
// export const ThirdRowData = [
//     {
//         id: 1,
//         src: '/assets/img/3번째 줄-02.jpg',
//     },
//     {
//         id: 2,
//         src: '../../assets/img/3번째 줄-03.jpg',
//     },
//     {
//         id: 3,
//         src: '../../assets/img/3번째 줄-04.jpg',
//     },
//     {
//         id: 4,
//         src: '../../assets/img/3번째 줄-05.jpg',
//     },
//     {
//         id: 5,
//         src: '../../assets/img/3번째 줄-06.jpg',
//     },
//     {
//         id: 6,
//         src: '../../assets/img/3번째 줄-07.jpg',
//     },
//     {
//         id: 7,
//         src: '../../assets/img/3번째 줄-08.jpg',
//     },
//     {
//         id: 8,
//         src: '../../assets/img/3번째 줄-09.jpg',
//     },
//     {
//         id: 9,
//         src: '../../assets/img/3번째 줄-10.jpg',
//     },
//     {
//         id: 10,
//         src: '../../assets/img/3번째 줄-11.jpg',
//     },
//     {
//         id: 11,
//         src: '../../assets/img/3번째 줄-12.jpg',
//     },
//     {
//         id: 12,
//         src: '../../assets/img/3번째 줄-13.jpg',
//     },
//     {
//         id: 13,
//         src: '../../assets/img/3번째 줄-14.jpg',
//     },
//     {
//         id: 14,
//         src: '../../assets/img/3번째 줄-15.jpg',
//     },
//     {
//         id: 15,
//         src: '../../assets/img/3번째 줄-16.jpg',
//     },
//     {
//         id: 16,
//         src: '../../assets/img/3번째 줄-17.jpg',
//     },
//     {
//         id: 17,
//         src: '../../assets/img/3번째 줄-18.jpg',
//     },
//     {
//         id: 18,
//         src: '../../assets/img/3번째 줄-19.jpg',
//     },
//     {
//         id: 19,
//         src: '../../assets/img/3번째 줄-20.jpg',
//     },
//     {
//         id: 20,
//         src: '../../assets/img/3번째 줄-21.jpg',
//     },
// ];

export const DoodleBoardImage = [
    {
        id: 1,
        src: '/assets/doodleboard_img/1.png',
    },
    {
        id: 2,
        src: '/assets/doodleboard_img/2.png',
    },
    {
        id: 3,
        src: '/assets/doodleboard_img/3.png',
    },
    {
        id: 4,
        src: '/assets/doodleboard_img/4.png',
    },
    {
        id: 5,
        src: '/assets/doodleboard_img/5.png',
    },
    {
        id: 6,
        src: '/assets/doodleboard_img/6.png',
    },
    {
        id: 7,
        src: '/assets/doodleboard_img/7.png',
    },
    {
        id: 8,
        src: '/assets/doodleboard_img/8.png',
    },
    {
        id: 9,
        src: '/assets/doodleboard_img/9.png',
    },
    {
        id: 10,
        src: '/assets/doodleboard_img/10.png',
    },
];

export const PaintData = [
    {
        id: 1,
        src: '/assets/doodleboard_img/color/1.png',
        cursor: '/assets/doodleboard_img/color/sample.png',
        color: '#FFDE59',
    },
    {
        id: 2,
        src: '/assets/doodleboard_img/color/2.png',
        color: '#5271FF',
    },
    {
        id: 3,
        src: '/assets/doodleboard_img/color/3.png',
        color: '#8C52FF',
    },
    {
        id: 4,
        src: '/assets/doodleboard_img/color/4.png',
        color: '#FA8939',
    },
    {
        id: 5,
        src: '/assets/doodleboard_img/color/5.png',
        color: '#77E98A',
    },
    {
        id: 6,
        src: '/assets/doodleboard_img/color/6.png',
        color: '#38BED3',
    },
    {
        id: 7,
        src: '/assets/doodleboard_img/color/7.png',
        color: '#F0492D',
    },
];

export const example = [
    {
        id: 1,
        src: '/assets/doodleboard_img/1.png',
    },
    {
        id: 2,
        src: '/assets/doodleboard_img/2.png',
    },
    {
        id: 3,
        src: '/assets/doodleboard_img/4.png',
    },
    {
        id: 4,
        src: '/assets/doodleboard_img/4.png',
    },
    {
        id: 5,
        src: '/assets/doodleboard_img/5.png',
    },
    {
        id: 6,
        src: '/assets/doodleboard_img/6.png',
    },
    {
        id: 7,
        src: '/assets/doodleboard_img/7.png',
    },
    {
        id: 8,
        src: '/assets/doodleboard_img/8.png',
    },
    {
        id: 9,
        src: '/assets/doodleboard_img/9.png',
    },
    {
        id: 10,
        src: '/assets/doodleboard_img/10.png',
    },
    {
        id: 10,
        src: '/assets/doodleboard_img/10.png',
    },
    {
        id: 10,
        src: '/assets/doodleboard_img/10.png',
    },
];
