import React from 'react';
import styled from 'styled-components';

import NewsPaperImg from '../../assets/신문 팝업창 (모바일).png';
const NewsPaperModal = () => {
    return (
        <RootNewsPaperContainer>
            <ImageBox>
                <Image src={NewsPaperImg} alt={'newspaper'} />
            </ImageBox>
        </RootNewsPaperContainer>
    );
};

export default NewsPaperModal;

export const RootNewsPaperContainer = styled.div``;

export const ImageBox = styled.div`
    position: relative;
`;
export const Image = styled.img`
    width: 100%;
    height: 700px;
    object-fit: contain;
    @media ${({ theme }) => theme.device.tablet} {
        //width: 55%;
        max-width: 45vh;
        width: 100%;
        //height: 65%;
        height: 100%;
        max-height: 650px;
    }
`;
