import React from 'react';

import NextImg from '../../assets/img/Group 129.png';
import styled from 'styled-components';
const NextArrow = ({ onClick }) => {
    return (
        <Div onClick={onClick}>
            <img src={NextImg} />
        </Div>
    );
};

export default NextArrow;
export const Div = styled.div`
    width: 70px;
    height: 70px;
    position: absolute;
    right: 2.5%;
    bottom: 50%;
    z-index: 99;
    text-align: right;
    @media ${({ theme }) => theme.device.tablet} {
        max-width: 50px;
        max-height: 50px;
    }
    @media ${({ theme }) => theme.device.medium} {
        max-width: 35px;
        max-height: 35px;
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%, 50%);
        background-color: gray;
        color: #fff;
        width: 100%;
        max-width: 70px;
        max-height: 70px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s;
        z-index: 1;
        // 화살표가 뒤에 묻히길래 z-index 주었다.
    }
`;
export const DivPre = styled.div`
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 99;
    bottom: 50%;
    left: 2%;
    @media ${({ theme }) => theme.device.tablet} {
        max-width: 50px;
        max-height: 50px;
    }
    @media ${({ theme }) => theme.device.medium} {
        max-width: 35px;
        max-height: 35px;
    }
    text-align: left;
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%, 50%);
        background-color: gray;
        width: 100%;
        max-width: 70px;
        max-height: 70px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s;
        z-index: 1;
        // 화살표가 뒤에 묻히길래 z-index 주었다.
    }
`;
