import React, { useState } from 'react';
import styled from 'styled-components';
import sendModalTitle from '../../assets/send_modal_title.png';
import { Width100ImgObjectFitContain } from '../nav/styles';
import { SendInput } from '../inputs';
import { FlexAlignCenterJustifyCenterContainer } from '../container/styles';
import { SendButton } from '../button';
const SendModal = ({ onClickSubmit, setValue, value }) => {
    const handleChange = (e) => {
        setValue((prev) => {
            let data = { ...prev };
            data[e.target.name] = e.target.value;
            return data;
        });
    };
    return (
        <SendRootContainer
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div>
                <Width100ImgObjectFitContain src={sendModalTitle} />
            </div>
            <InputBox>
                <div>
                    <p>Instagram ID</p>
                    <SendInput
                        name={'instagramId'}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        value={value.instagramId}
                    />
                </div>
                <div>
                    <p>Twitter ID</p>

                    <SendInput
                        name={'twitterId'}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        value={value.twitterId}
                    />
                </div>
                <FlexAlignCenterJustifyCenterContainer>
                    <SendButton onClick={onClickSubmit} text={'submit'} />
                </FlexAlignCenterJustifyCenterContainer>
            </InputBox>
        </SendRootContainer>
    );
};

export default SendModal;

const SendRootContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    @media ${({ theme }) => theme.device.tablet} {
        width: 330px;
    }
    width: 100%;
    background: white;
    padding: 10px 30px;
    color: #000;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
